//
// Use this to write your custom SCSS
//

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*
 * Прячем скролл-бары в <chat-more-group> и <members>
 */
.hide-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hide-scrollbar::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}


// Скрываем правые блоки (chat-more-group и add-member по-умолчанию).
.offcanvas-aside {
  &.offcanvas-end:not(.show) {
    width: 0;
  }
}

// Сразу прорисовываем ширину правых скрытых блоков, чтобы потом при клике на "..." они открылись на заданную ширину
.offcanvas-aside {
  &.offcanvas-end {
    width: 100%;
  }
}

/**
 * Для мобильных устройств должно быть не vh, а svh,
 * чтобы учитывать высоту панелек браузера телефона.
 */
@media (max-width: 991px) {
  .sidebar {
    width: 100%;
    height: -webkit-fill-available;
    height: calc(100svh - 58px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    width: 100%;
    height: -webkit-fill-available;
    height: calc(100svh - 92px);
  }
}

/**
 * 100% высота для мобилок (для формы логина и т.д.)
 */
.min-svh-100 {
  min-height: 100svh !important;
}
