//
// Zoom vanilla
//

img[data-action="zoom"] {
    cursor: zoom-in;
}

.zoom-img,
.zoom-img-wrap {
    position: relative;
    z-index: $zindex-modal;
    transition: all 300ms;
}

img.zoom-img {
    cursor: zoom-out;
}

.zoom-overlay {
    cursor: zoom-out;
    z-index: $zindex-modal - 1;
    background: $body-bg;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: "alpha(opacity=0)";
    opacity: 0;
    transition: opacity 300ms;
}

.zoom-overlay-open .zoom-overlay {
    filter: "alpha(opacity=100)";
    opacity: 1;
}